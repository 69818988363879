




















import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import { BrandDocument } from '@/models';
import BrandStore from '@/store/modules/brand';
import { CategoryType, CategoryTypeList } from '@/constants';

interface IBrandCategoryMap {
  category: CategoryType;
  label: string;
  brands: BrandDocument[];
}

@Component({
  name: 'BrandChooser',
})
export default class extends Vue {
  @Prop() private onBeforeChange!: ((newChange: string[], prevChange: string[]) => void | boolean | Promise<boolean>) | undefined;
  @Prop() private onChange!: ((newChange: string[], prevChange: string[]) => void) | undefined;
  @Prop() private disabled!: boolean;

  @Model('change', { type: Array }) private value!: string[];

  private selectedTab: string = '';
  private brandCategoryMap: IBrandCategoryMap[] = (undefined as unknown) as IBrandCategoryMap[];
  private previousSelected: string[] = (undefined as unknown) as string[];

  private created(): void {
    /**
     * Build the brandCategoryMap
     */
    const categoryBrands = Object.entries(CategoryTypeList).map(([category, label]) => {
      const brands = BrandStore.list.filter(brand => brand.category === category);
      return { category, label, brands } as IBrandCategoryMap;
    });
    this.brandCategoryMap = categoryBrands.filter(categoryBrand => categoryBrand.brands.length > 0);

    this.previousSelected = [];

    if (this.value.length > 0) {
      this.selectedTab = this.brandCategoryMap.reduce(
        (accumulator: string, setup) => accumulator || (this.isBrandSelected(setup.brands) ? setup.category : ''),
        '',
      );
    }

    if (!this.selectedTab) {
      this.selectedTab = this.brandCategoryMap[0].category;
    }
  }

  private isBrandSelected(brands: BrandDocument[]): boolean {
    return brands.some(x => this.value.includes(x.id));
  }

  private async handleSingleChange(e: MouseEvent, selectedBrand: BrandDocument): Promise<void> {
    if (!(e.target as HTMLInputElement).value) {
      return;
    }

    /**
     * We haven't modified the model yet so we should still have the old "previous" value
     */
    this.previousSelected = this.value.slice();

    if (this.onBeforeChange) {
      e.preventDefault();

      const newChange: string[] = this.previousSelected.slice();
      const pos: number = newChange.indexOf(selectedBrand.id);

      if (pos === -1) {
        newChange.push(selectedBrand.id);
      } else {
        newChange.splice(pos, 1);
      }

      const rtn = await this.onBeforeChange(newChange, this.value);

      if (rtn !== false) {
        if (pos === -1) {
          this.value.push(selectedBrand.id);
        } else {
          this.value.splice(pos, 1);
        }

        this.handleGroupChange();
      }
    }
  }

  private handleGroupChange() {
    if (this.onChange) {
      this.onChange(this.value, this.previousSelected);
    }
  }
}
