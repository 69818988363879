



import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'ImageViewer',
})
export default class extends Vue {
  @Prop({ default: '' }) private src!: string;
  @Prop() private className!: string;
}
