









import { Component, Vue, Prop } from 'vue-property-decorator';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import 'video.js/dist/video.min.js';

@Component({
  name: 'VideoPlayer',
})
export default class extends Vue {
  @Prop() private src!: string;
  @Prop() private mimeType!: string;
  @Prop() private className!: string;

  private player: any = null;

  private mounted() {
    this.player = videojs(this.$refs.videoPlayer as Element, {}, function onPlayerReady() {
      console.log('Player ready');
    });
  }

  /**
   * @TODO Not too sure if we're interpreting the mimetype incorrectly on upload, or if video.js is not working right (doubt it),
   *       but for now we need to remap some mime types to make it work
   */
  get transformedMimeType(): string {
    return this.mimeType === 'video/quicktime' ? 'video/mp4' : this.mimeType;
  }

  private beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
