

























































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import { IS3Object } from '@/util';
import { ImageViewer, VideoViewer } from '@/components';
type FileSetupStatus = 'ready' | 'uploading' | 'success' | 'failed';

interface InternalFileSetup extends IS3Object {
  status: FileSetupStatus;
  percentage: number;
  file: File;
}

@Component({
  name: 'FileUploader',
  components: {
    ImageViewer,
    VideoViewer,
  },
})
export default class extends Vue {
  @Prop() private uploadPath!: string;
  @Prop({ default: true }) private appendRandom!: boolean;
  @Prop({ default: false }) private doRealRemove!: boolean;
  @Prop({ default: false }) private disabled!: boolean;
  @Prop({ default: 'File uploader' }) private uploadHeading!: string;
  @Prop({ default: 'Upload' }) private uploadButtonText!: boolean;
  @Prop({ default: 'success' }) private uploadButtonType!: string;
  @Prop({ default: 'large' }) private uploadButtonSize!: string;
  @Prop({ default: '' }) private uploadButtonStyle!: string;
  @Prop({ default: 'view-file' }) private viewFileClassName!: string;
  @Prop({ default: false }) private viewOnly!: boolean;
  @Prop() private allowableMimeTypes!: string | string[];
  @Prop() private allowableFileTypes!: string | string[];
  @Prop({ default: -1 }) private maxFileSize!: number;
  @Prop() private onBeforeUpload!: ((file: File) => void | boolean | Promise<boolean>) | undefined;
  @Prop() private onBeforeRemove!: ((fileSetup: IS3Object) => void | boolean | Promise<boolean>) | undefined;
  @Prop() private onUploadSuccess!: ((fileSetup: IS3Object) => void) | undefined;
  @Prop() private onUploadFailed!: ((file: File, e: Error) => void) | undefined;
  @Prop() private onRemoveSuccess!: ((fileSetup: IS3Object) => void) | undefined;
  @Prop() private onRemoveFailed!: ((fileSetup: IS3Object, e: Error) => void) | undefined;

  @PropSync('fileSetup') private syncedFileSetup!: IS3Object | undefined;

  private dialogVisible: boolean = false;
  private showView: boolean = false;
  private cleanedFileTypes: string[] = (undefined as unknown) as string[];
  private _maxFileSize: number = -1;
  private fileToUpload: File | null = null;
  private beforeUploadCheck: boolean = false;
  private isUploading: boolean = false;
  private uploadProgress: number = 0;
  private isDownloading: boolean = false;
  private fileUrl: string | null = '';
  private fileTypes: any = {
    video: {
      label: 'Video',
      typeLabel: 'video',
      mimeTypes: ['video/mp4', 'video/mpeg', 'video/x-msvideo', 'video/x-ms-wmv', 'video/quicktime', 'audio/mp4m'],
      fileExtns: ['mp4', 'mov', 'h264'],
      defaultMaxSize: 2048 * 1024 * 1024,
    },
    image: {
      label: 'Image',
      typeLabel: 'image',
      mimeTypes: ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'],
      defaultMaxSize: 2 * 1024 * 1024,
    },
    excel: {
      label: 'Excel',
      typeLabel: 'xlsx',
      mimeTypes: ['application/msexcel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      defaultMaxSize: 10 * 1024 * 1024,
    },
  };

  private async created() {
    if (this.allowableFileTypes) {
      this.cleanedFileTypes = Array.isArray(this.allowableFileTypes) ? [...this.allowableFileTypes] : [this.allowableFileTypes];
    } else {
      this.cleanedFileTypes = [];
    }

    this._maxFileSize = this.maxFileSize;
    if (this._maxFileSize === -1 && this.cleanedFileTypes.length > 0) {
      this._maxFileSize = this.fileTypes[this.cleanedFileTypes[0]].defaultMaxSize;
    }
    if (this.hasExistingFile()) {
      this.fileUrl = (await Storage.get(this.syncedFileSetup?.key || '')) as string;
    }
  }

  get isImage(): boolean {
    return this.isType('image', this.syncedFileSetup?.mimetype, this.syncedFileSetup?.name);
  }

  get isVideo(): boolean {
    return this.isType('video', this.syncedFileSetup?.mimetype, this.syncedFileSetup?.name);
  }

  get isExcel(): boolean {
    return this.isType('excel', this.syncedFileSetup?.mimetype, this.syncedFileSetup?.name);
  }

  private isType(type: string, mimeType: string | undefined, fileName: string | undefined): boolean {
    if (!mimeType) {
      return false;
    }

    const fileType = this.fileTypes[type];
    if (!fileType) {
      return false;
    }

    if (!fileType.mimeTypes.includes(mimeType)) {
      return false;
    }
    // If no file extn then match only by mimetype
    if (!fileType.fileExtns) {
      return true;
    }

    if (!fileName) {
      return false;
    }

    const fileParts = fileName.split('.');
    const fileExtn = fileParts[fileParts.length - 1].toLowerCase();
    return fileType.fileExtns.includes(fileExtn);
  }

  get videoAction() {
    return this.showView ? 'Close Video' : 'Play Video';
  }

  private openDialog(): void {
    this.fileToUpload = null;
    this.isUploading = false;
    this.dialogVisible = true;
  }

  private closeDialog(): void {
    this.dialogVisible = false;
  }

  private async checkCloseDialog(done: () => {}): Promise<void> {
    if (!this.isUploading) {
      done();
      return;
    }

    this.cancelUpload();
  }

  private hasExistingFile(): boolean {
    return !!this.syncedFileSetup?.key;
  }

  private constructFileTypeList(): string {
    const len = this.cleanedFileTypes.length;
    if (len === 0) {
      return '';
    }
    const fileTypeLabels = this.cleanedFileTypes.map(fileType => this.fileTypes[fileType].typeLabel);
    if (len === 1) {
      return fileTypeLabels[0];
    }
    return `${fileTypeLabels.slice(0, -1).join(', ')} & ${fileTypeLabels[len - 1]}`;
  }

  private formatFileSize(bytes: number, decimalPoint?: number) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimalPoint || 2,
      sizes = [' Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  get fileTip() {
    const parts = [];
    if (this.cleanedFileTypes.length > 0) {
      parts.push(`${this.constructFileTypeList()} files`);
    }
    if (this._maxFileSize > -1) {
      parts.push(`under ${this.formatFileSize(this._maxFileSize)}`);
    }
    return parts.length > 0 ? `Only ${parts.join(' ')}` : null;
  }

  private async handleBeforeUpload(file: File): Promise<boolean> {
    this.beforeUploadCheck = false;

    if (this.hasExistingFile()) {
      try {
        await this.$confirm('This will overwrite your existing file. Are you sure?', {
          type: 'warning',
          confirmButtonText: 'Overwrite',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Keep Existing',
        });
      } catch (e) {
        return false;
      }
    }

    if (this.cleanedFileTypes.length > 0) {
      const valid = this.cleanedFileTypes.some(fileType => this.isType(fileType, file.type, file.name));
      if (!valid) {
        await this.$alert(`File type not allowed. Only ${this.constructFileTypeList()} files are allowed.`);
        return false;
      }
    }

    if (this._maxFileSize > -1 && file.size > this._maxFileSize) {
      await this.$alert(`Upload file size is ${this.formatFileSize(file.size)}. Cannot be greater than ${this.formatFileSize(this._maxFileSize)}.`);
      return false;
    }

    if (this.onBeforeUpload) {
      try {
        const rtn = await this.onBeforeUpload(file);

        if (rtn === false) {
          return false;
        }
      } catch (e) {
        return false;
      }
    }

    this.fileToUpload = file;
    this.beforeUploadCheck = true;

    return true;
  }

  private async stopUploading(): Promise<void> {
    this.isUploading = false;
  }

  private async fileUploader(): Promise<void> {
    if (!this.beforeUploadCheck) {
      return;
    }

    this.isUploading = true;
    this.uploadProgress = 0;

    let uploadFullPath: string = this.uploadPath;

    if (this.appendRandom) {
      uploadFullPath = `${uploadFullPath}-${Math.floor(Math.random() * 999999999)}`;
    }

    /**
     * AWS S3 treats any prefixing forward slashes as a separate folder (separate redundant folder called '/'), so instead of
     * changing all paths to strip out that prefix slash AND having to move existing buckets around, we just make sure that the
     * path ALWAYS starts with that redundant prefix forward slash
     */
    if (uploadFullPath.substr(0, 1) !== '/') {
      uploadFullPath = `/${uploadFullPath}`;
    }

    try {
      await Storage.put(uploadFullPath, this.fileToUpload, {
        progressCallback: (progress: any) => {
          this.uploadProgress = Math.round((progress.loaded / progress.total) * 100);
        },
      });

      const fileSetup = {
        key: uploadFullPath,
        name: (this.fileToUpload as File).name,
        mimetype: (this.fileToUpload as File).type,
      };

      if (this.onUploadSuccess) {
        this.onUploadSuccess({ ...fileSetup });
      }

      this.isUploading = false;
      this.uploadProgress = 0;
      this.syncedFileSetup = fileSetup;
      this.fileUrl = (await Storage.get(uploadFullPath)) as string;

      this.closeDialog();
    } catch (e) {
      this.isUploading = false;
      this.uploadProgress = 0;

      if (this.onUploadFailed) {
        this.onUploadFailed(this.fileToUpload as File, e as any);
      }
    }
  }

  private async cancelUpload(): Promise<void> {
    try {
      await this.$confirm('Are you sure you wish to cancel the current upload?', {
        type: 'warning',
        confirmButtonText: 'Cancel Upload',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Continue Uploading',
      });
    } catch (e) {
      return;
    }

    this.stopUploading();
  }

  private playVideo(): void {
    return;
  }

  private async downloadFile(): Promise<void> {
    if (this.syncedFileSetup?.key) {
      this.isDownloading = true;

      const result = (await Storage.get(this.syncedFileSetup.key, { download: true })) as Record<string, any>;

      saveAs(result.Body as Blob, this.syncedFileSetup.name);

      this.isDownloading = false;
    }
  }

  private async deleteFile(): Promise<void> {
    try {
      await this.$confirm('This will delete your existing file. Are you sure?', {
        type: 'warning',
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
      });
    } catch (e) {
      return;
    }

    const deletedSetup = { ...this.syncedFileSetup } as IS3Object;

    if (this.onBeforeRemove) {
      try {
        const rtn = await this.onBeforeRemove(deletedSetup);

        if (rtn === false) {
          return;
        }
      } catch (e) {
        return;
      }
    }

    if (this.doRealRemove) {
      try {
        await Storage.remove(deletedSetup.key);
      } catch (e) {
        if (this.onRemoveFailed) {
          this.onRemoveFailed(deletedSetup, e as any);
        }
      }
    }

    this.syncedFileSetup = {
      key: '',
      name: '',
      mimetype: '',
    };
    this.fileUrl = null;

    if (this.onRemoveSuccess) {
      this.onRemoveSuccess(deletedSetup);
    }
  }
}
