










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'GreyBox',
})
export default class extends Vue {
  @Prop() private className!: string;
}
